@import './variables';

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
:root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  font-family: $primary-font;
  font-weight: 400;
  scroll-behavior: smooth;
  background-color: $background-color;

}

a {
  text-decoration: none;
}

img {
  height: auto;
}

input,
button,
textarea,
select {
  font-family: inherit;
}

button {
  border: none;
  cursor: pointer;
}


textarea {
  resize: vertical;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
