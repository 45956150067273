
.task-input {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  align-items: center;

  input {
    flex: 3;
    padding: 12px;
    border: none;
    border-radius: 8px 0 0 8px;
    outline: none;
    font-size: 16px;
    background-color: #f5f5f5;
    color: #333;

    &::placeholder {
      color: #aaa;
    }
  }

  button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}