@import "../../variables";



.Button__input {

  background-color: $btn-color;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 5px;

}

.Button__input--clickable {
    cursor: pointer;
}