@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;700&display=swap')
//colors
$background-color: #f0f0f0
$btn-color:  #007bff

// fonts
// style
$primary-font: Catamaran, sans-serif


//  size
$fs-xs: 1.2rem
$fs-sm: 1.4rem
$fs-md: 1.5rem
$fs-lg: 1.6rem
$fs-xl: 1.8rem
$fs-xxl: 2.2rem
$fs-xxxl: 2.4rem
$fs-header: 2rem


// other
$width-80: 80%